export const LOGIN = '/';
export const ERROR_PAGE = '/not-found';

export const DASHBOARD = 'dashboards';
export const TRANSACTIONS = 'transactions';
export const TENANTS = 'tenants';
export const CONFIG = 'configuration/:tenantId';
export const TEMPLATE = 'template';
export const NOTIFICATION = 'notification';
export const SUMMARY = 'summary';
