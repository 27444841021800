import { Button, Container, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // This will be used for the bullet point
import instructions from "src/shared/constants/instructions";
const HeaderInfo = () => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    }
    const handleOpen = () => {
        setOpen(true);
    }

    

    return (
        <>
            <Tooltip
                className="RecentOrderEditButton"
                title="Help"
                arrow>
                <IconButton color="primary" onClick={handleOpen}>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} fullWidth sx={{ '& .MuiDialog-paper': { width: '800px', maxWidth:"800px" } }}>
                <DialogTitle>
                    <Typography variant="h3" component="h3" gutterBottom style={{ fontWeight: '100' }}>
                    Instructions
                    </Typography>
                    
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        sx={{ position: 'absolute', right: 40, top: 16 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        p: 3
                    }}
                >
                    <Container maxWidth="sm" style={{ padding: '2px', maxWidth:'700px'}}>
                        {instructions.map((section, sectionIndex) => (
                            <div key={sectionIndex} style={{ paddingBlock:'1px'}}>
                                <Typography variant="h6" gutterBottom>
                                    {section.title}
                                </Typography>
                                <List sx={{paddingTop: 0}}>
                                    {section.steps.map((step, stepIndex) => (
                                        <ListItem key={stepIndex} sx={{paddingBlock: '0px'}}> 
                                            <ListItemIcon>
                                                <FiberManualRecordIcon fontSize="small" /> {/* Small bullet point */}
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <Typography variant="body1"> 
                                                        {step}
                                                    </Typography>
                                                }
                                              />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        ))}
                    </Container>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default HeaderInfo;
