import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/core/layout/SidebarLayout';
import BaseLayout from 'src/core';

import { GuardedRoute } from './guarded-routes';
import {
  LOGIN,
  SIGNUP,
  TRANSACTIONS,
  ERROR_PAGE,
  TENANTS
} from './../shared/constants/constants';
import TanantList from '../modules/Tenant/tenant.list';
import { SuspenseLoader } from 'src/shared/components/index';
import * as ROUTES from '../shared/constants/routes';
import TenantDashboard from 'src/modules/Tenant/tenant.dashboard';
// import ChannelDashboard from 'src/tenant/Config/channel.dashboard';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Login = Loader(lazy(() => LOGIN));
const ErrorPage = Loader(lazy(() => ERROR_PAGE));

const SignUp = Loader(lazy(() => SIGNUP));

// Dashboards

const Transactions = Loader(lazy(() => TRANSACTIONS));
const Tenants = Loader(
  lazy(() => import('src/modules/Tenant/tenant.dashboard'))
);
const ChannelDashboard = Loader(
  lazy(() => import('src/modules/Tenant/Config/channel.dashboard'))
);
const TemplateDashboard = Loader(
  lazy(() => import('src/modules/Template/template.dashboard'))
);
const NotificationDashboard = Loader(
  lazy(() => import('src/modules/Notification/notification.dashboard'))
);

const Summary = Loader(lazy(() => import('src/modules/Summary/Summary')));
const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />
      }
    ]
  },
  {
    path: 'signup',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <SignUp />
      }
    ]
  },
  ,
  {
    path: ROUTES.DASHBOARD,
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.TENANTS} replace />
      },
      {
        path: ROUTES.TRANSACTIONS,
        element: <GuardedRoute component={Transactions} />
      },
      {
        path: ROUTES.TENANTS,
        element: <GuardedRoute component={Tenants} />
      },
      {
        path: ROUTES.CONFIG,
        element: <GuardedRoute component={ChannelDashboard} />
      },
      {
        path: ROUTES.TEMPLATE,
        element: <GuardedRoute component={TemplateDashboard} />
      },
      {
        path: ROUTES.NOTIFICATION,
        element: <GuardedRoute component={NotificationDashboard} />
      },
      {
        path: ROUTES.SUMMARY,
        element: <GuardedRoute component={Summary} />
      }
    ]
  },
  {
    path: ROUTES.ERROR_PAGE,
    element: <ErrorPage />
  }
];

export default routes;
