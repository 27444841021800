import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import { SidebarMenus } from 'src/shared/components/index';
import * as ROUTES from 'src/shared/constants/routes';
import BookIcon from '@mui/icons-material/Book';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
const SidebarMenu = () => {
  return (
    <>
      <SidebarMenus
        menuname={'Dashboards'}
        option={[
          {
            pathname: ROUTES.DASHBOARD,
            subpathname: ROUTES.SUMMARY,
            icon: <DashboardIcon />,
            submenuname: 'Summary'
          },
          {
            pathname: ROUTES.DASHBOARD,
            subpathname: ROUTES.TENANTS,
            icon: <BusinessIcon />,
            submenuname: 'Tenants'
          },
          {
            pathname: ROUTES.DASHBOARD,
            subpathname: ROUTES.TEMPLATE,
            icon: <IntegrationInstructionsIcon />,
            submenuname: 'Templates'
          },
          // {
          //   pathname: ROUTES.DASHBOARD,
          //   subpathname: ROUTES.NOTIFICATION,
          //   icon: <BookIcon />,
          //   submenuname: 'Notification'
          // }
        ]}
      />
    </>
  );
};

export default SidebarMenu;
