// Instructions grouped by titles
const instructions = [
    {
        title: "Create a Tenant:",
        steps: [
            `Go to the 'Tenants' menu.`,
            "Click on 'Create Tenant'.",
            "Enter the tenant name and submit.",
            "The new tenant should appear in the tenant list."
        ]
    },
    {
        title: "View Configuration:",
        steps: [
            "Click on the 'View Configuration' button from the tenant list.",
            "This will display the configurations for the selected tenant."
        ]
    },
    {
        title: "Create a Config:",
        steps: [
            "Click on the 'Create Config' button.",
            "From the dropdown list, select the channel type. For this example, choose 'Email.'",
            "Select the appropriate provider from the dropdown list, such as SendGrid.",
            "API Key: Enter your API key. For example, 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.",
            "From Email: Enter the email address that will appear as the sender. For example, ganesh.hurgule@indexnine.com.",
            "Submit the form to create the configuration."
        ]
    },
    {
        title: "Create a Template:",
        steps: [
            "Navigate to the 'Template' menu.",
            "Click on 'Add Template'.",
            "Choose the tenant name from the dropdown.",
            "Enter a template name.",
            "Upload the file from your computer.",
            "Click 'Upload' to save the template."
        ]
    },
    {
        title: "Send Notification:",
        steps: [
            "Click on the 'Send Notification' button from the template list.",
            "All values will be pre-populated.",
            "Review and send the notification."
        ]
    }
];

export default instructions